.ChatHeader {
	display: grid;
	grid-template-columns: 1fr min-content min-content;
	grid-auto-flow: column;
	background-color: var(--color-primary);
	align-items: center;
	color: var(--color-white);
	gap: .5rem;
	padding: 1rem 2rem;
	z-index: 10;
	height: 50px;
}

.ChatHeader .ButtonIcon {
	width: 3rem;
}

.ChatHeader__avatar {
	max-height: 45px;
	display: flex;
	align-items: center;
}

.ChatHeader__avatar .ResponseTime {
	font-size: 1.1rem;
}

.ChatHeader__avatar .AvatarName {
	width: 4rem;
	height: 4rem;
	background-color: var(--color-white);
}

.ChatHeader__agent-name {
	padding-left: 1.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 145px;
}

.ChatHeader__backIcon {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ChatHeader__backIcon .ButtonIcon {
	padding-left: 10px;
}

.ChatHeader__backIcon > svg > path {
	fill: var(--color-white);
}

.ChatHeader__minimize .ButtonIcon {
	padding-left: 5px;
}

.ChatHeader__info {
	display: grid;
	gap: .2rem;
}

.ChatHeader__agentName {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ChatHeader__lastSeen{
	font-size: 1.1rem;
	opacity: 0.5;
}

.ChatHeader__video {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ChatHeader__video svg path {
	fill: var(--color-white);
}