.CallControls {
	display: grid;
	gap: 2rem;
	justify-items: center;
	color: var(--color-white);
}

.CallControls__button > button > svg > path {
	fill: var(--color-white);
}

.CallControls__button--disabled > button > svg > path {
	fill: var(--color-grey-dark-2)
}

.CallControls__button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.5rem;
	height: 4.5rem;
	border-radius: 50%;
}

.CallControls__buttons {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: repeat(4, max-content);
	gap: .5rem;
	justify-items: center;
	align-items: center;
}

.CallControls__button > button {
	width: 4.5rem;
	height: 4.5rem;
}
.CallControls__button svg {
	width: 2rem;
	height: 2rem;
}
