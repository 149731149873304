.SingleChoice {
	display: flex;
	flex-wrap: wrap;
	padding: 0 20px 20px 20px;
	list-style: none;
	color: var(--color-primary);
}

.SingleChoice__choice {
	margin: 5px;
}

.SingleChoice__choice__label {
	display: flex;
	border: solid 2px var(--color-primary);
	padding: 1.5rem;
	border-radius: 4px;
	justify-content: center;
}

.SingleChoice__choice__input {
	display: none;
}

.SingleChoice__choice__input:hover + label {
	color: var(--color-primary);
	cursor: pointer;
}

.SingleChoice__choice__input:checked + label {
	color: var(--color-white);
	background-color: var(--color-primary);
}