.Backdrop {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, .25);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	border-radius: 5px;
}