.SelectDepartment {
	display: grid;
	grid-template-rows: min-content 1fr min-content;
	position: relative;
	width: 100vw;
	height: 100vh;
	color: var(--color-primary);
}

.SelectDepartmentList {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 80vh;
}

@media screen and (max-height: 600px) {
	.SelectDepartmentList {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 75vh;
	}	
}

.SelectDepartment__choice {
	margin: 3px;
	align-items: center;
	border: solid 2px var(--color-primary);
}

.SelectDepartment__choice__label {
	display: flex;
	padding: 1rem;
	border-radius: 4px;
	justify-content: left;
}

.SelectDepartment__choice__input {
	display: none;
}

.SelectDepartment__choice__active {
	background-color: #b7b7b7;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin: 4px 10px;
}

.SelectDepartment__choice__active__on {
	background-color: #7ed321;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin: 4px 10px;
}

.SelectDepartment__choice__message {
	margin-left: 10px;
	font-size: 12px;
	color: #b7b7b7;
	margin-top: 2px;
}

.SelectDepartment__choice__button {
	display: inline-block;
	width: 30px; height: 19px;
	position: fixed;
	right: 30px;
	background-color: transparent;
	border-top: 0px;
	border-left: 0px;
	padding: 0;
}

.SelectDepartment__choice__more {
	display: inline-block;
	border-right: 4px solid #b7b7b7;
	border-bottom: 4px solid #b7b7b7;
	width: 10px; height: 10px;
	transform: rotate(45deg);
	position: relative;
	top: -2px;
	transition: all 0.2s ease;
}

.SelectDepartment__choice__less {
	display: inline-block;
	border-right: 4px solid #b7b7b7;
	border-bottom: 4px solid #b7b7b7;
	width: 10px; height: 10px;
	transform: rotate(-135deg);
	position: relative;
	top: 5px;
	transition: all 0.2s ease;
}

.SelectDepartment__choice__officeHour {
	display: block;
	color: black;
	padding: 0px 20px 10px 20px;
}

.SelectDepartment__choice__officeHour__hidden {
	display: none;
	color: black;
	padding: 0px 20px 10px 20px;
}

.SelectDepartment__choice__input:hover + label {
	color: var(--color-primary);
	cursor: pointer;
}

.SelectDepartment__choice__input:checked + label {
	color: var(--color-white);
	background-color: var(--color-primary);
}