*{
  margin: 0;
  padding: 0;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px !important;
  background-color: var(--color-white);
  color: var(--color-grey-dark-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/montserrat/Montserrat-Italic.woff2) format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/montserrat/Montserrat-Regular.woff2) format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/montserrat/Montserrat-Medium.woff2) format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/montserrat/Montserrat-SemiBold.woff2) format('woff');
  font-style: normal;
  font-weight: 600;
}

input, select { font-size: 100% !important }