.CustomerInfo {
	display: grid;
	grid-auto-flow: row;
	gap: 1rem;
}

.CustomerInfo__button {
	width: 100%;
}

.CustomerInfo__button .Loader__img {
	width: 1.6rem;
	height: 1.6rem;
}
