.RateStar__form {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-around;
  align-items: center;
}

.RateStar input {
  display: none;
}

.RateStar__label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
}

.RateStar__label:hover {
  cursor: pointer;
}

.RateStar__label > svg > path {
  fill: var(--color-grey-light-3);
}

.RateStar__label--hovered > svg > path {
  fill: var(--color-yellow);
}

.RateStar--checked label > svg > path {
  fill: var(--color-yellow);
}

.RateStar input:checked ~ label > svg > path {
  fill: var(--color-grey-light-3);
}

.RateStar input:checked + label > svg > path {
  fill: var(--color-yellow);
}