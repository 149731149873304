.ChatInputText {
	display: flex;
	align-items: center;
	height: 100%;
	overflow-x: hidden;
}

.ChatInputText__input {
	display: flex;
	align-items: center;
	padding-left: 1.5rem;
	width: 100%;
	max-height: 12rem;
	overflow-y: auto;
	overflow-x: hidden;
	outline: none;
	padding: 2.2rem;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	word-break: break-word;
}

.ChatInputText__input:hover{
	cursor: text;
}

.ChatInputText__input:empty::before {
	content: attr(placeholder);
	color: var(--color-grey-light-4);
	display: block;
}

.ChatInputText--disabled {
	cursor: default;
}

.ChatInputText--disabled .ChatInputText__input {
	cursor: default;
}

.ChatInputText--disabled .ChatInputText__input:before {
	cursor: default;
}
