.Button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.4rem;
	font-size: 1.3rem;
	border-radius: 3px;
	border: none;
	outline: none;
	font-family: 'Montserrat';
	font-weight: 500;
	color: var(--color-white);
	cursor: pointer;
}

.Button:disabled {
	cursor: default;
	background-color: var(--color-grey-light-3) !important;
	color: var(--color-grey-dark-3);
}


.Button .Loader .Loader__img {
	width: 16px;
	height: 16px;
}
