.MessageTyping {
	display: grid;
	grid-auto-flow: column;
	justify-content: end;
	align-items: end;
	gap: .5rem;
}

.MessageTyping--client {
	justify-content: start;
}

.MessageTyping__animation {
	width: 2.4rem;
	padding: 0 1rem;
}

.MessageTyping__animation .dot {
	display:inline-block;
	width:.5rem;
	height:.5rem;
	border-radius:50%;
	margin-right:3px;
	background: var(--color-grey-dark-2);
	animation: wave 1.3s linear infinite;
}

.MessageTyping__animation .dot:nth-child(2) {
	animation-delay: -1.1s;
}

.MessageTyping__animation .dot:nth-child(3) {
	animation-delay: -0.9s;
}

.MessageTyping > .Bubble {
	padding: 1rem;
	height: 4rem;
	margin-right: -1rem;
	position: relative;
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-.6rem);
	}
}