.FinishModal {
	max-width: 300px;
	text-align: center;
	color: var(--color-grey-dark-1);
}

.FinishModal__actions {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	align-items: end;
	gap: .9rem;
	padding-top: 2rem;
}