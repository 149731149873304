.ErrorTemplate {
	padding: 10px;
	max-width: 300px;
	max-height: 200px;
}

.ErrorTemplate .Input {
	margin-top: 20px;
	margin-bottom: 5px;
}
