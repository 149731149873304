.ZenviaInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;
}

.ZenviaInfo > img {
    height: 1.5rem;
}

.ZenviaInfo > svg path {
    fill: var(--color-grey-dark-3);
}

.OmnizeInfo > img {
	height: 2rem;
}