.SendChatCopyModal {
	display: grid;
	width: 80%;
	justify-items: center;
	gap: 1.6rem;
	background-color: var(--color-white);
	padding: 3.5rem 2rem 3rem 2rem;
	border-radius: 8px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	color: var(--color-grey-dark-1);
}

.SendChatCopyModal__input {
	border: 1px solid var(--color-grey-light-3);
	border-radius: 3px;
	width: 100%;
}

.SendChatCopyModal__save {
	width: 100%;
}
