.MessageVideo {
	display: grid;
  	grid-auto-flow: column;
  	justify-content: start;
  	align-items: end;
  	gap: .5rem;
  	justify-content: start;
  	width: 100%;
}

.MessageVideo video {
	max-width: 20rem;
	border-radius: .4rem;
}

.MessageVideo--client {
	justify-content: end;
}