.MessageImage {
	display: grid;
  	grid-auto-flow: column;
  	justify-content: start;
  	align-items: end;
  	gap: .5rem;
  	justify-content: start;
  	width: 100%;
}

.MessageImage img {
	max-width: 20rem;
}

.MessageImage--client {
	justify-content: end;
}