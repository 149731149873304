.TransferTemplate {
	position: fixed;
	width: 100%;
	height: 100%;
	display: grid;
	align-content: center;
	justify-items: center;
	background-color: var(--color-white);
	gap: 4rem;
	font-size: 1.6rem;
	font-weight: 500;
	text-transform: uppercase;
}

.TransferTemplate__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 10rem;
	height: 10rem;
	border-radius: 50%;
	background-color: var(--color-grey-light-3);
	animation: expanding 2s infinite;
}

.TransferTemplate__icon svg {
	height: 7rem;
	width: 7rem;
}

.TransferTemplate__icon path {
	fill: var(--color-white);
}

@keyframes expanding {
	50% {
		transform: scale(1.1)
	}
}