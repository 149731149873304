.Attachment__label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}

.Attachment__label--disabled {
	display: none;
}

.Attachment__label:hover:not(.Attachment__label--disabled) {
  cursor: pointer;
}

.Attachment__label:hover:not(.Attachment__label--disabled) > svg > path {
  fill: var(--color-grey-dark-1)
}

.Attachment__label > svg > path {
  fill: var(--color-grey-light-3);
}

.Attachment__label > svg {
  width: 1.97rem;
  height: 3rem;
}

.Attachment__input {
  opacity: 0;
  position: fixed;
  top: -10rem;
}