.AvatarNameList {
	display: flex;
	align-items: center;
}

.AvatarNameList > div {
	width: 4rem;
	height: 4rem;
	border: 3px solid var(--color-primary);
}

.AvatarNameList > div:not(:first-child) {
	transform: translateX(-1.3rem);
}

.AvatarNameList--status::before {
	content: '';
	position: absolute;
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	bottom: 0;
	right: 0;
	z-index: 3;
	border: .15rem solid var(--color-grey-dark-1);
	transform: translateX(1.9rem);
}

.AvatarNameList--online::before {
	background-color: var(--color-green);
}

.AvatarNameList--offline::before {
	background-color: var(--color-grey-dark-1);
}

.AvatarNameList--connecting::before {
	background-color: var(--color-orange);
}

.AvatarNameList--small > div {
	width: 2.5rem;
	height: 2.5rem;
	margin: 0 -1.3rem 0 0;
	border: 2px solid var(--color-white);
}
.AvatarNameList--small {
	transform: translateX(-1.2rem);
}