.Bubble {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	border-radius: 4px;
	background-color: var(--color-grey-light-1);
	padding: 1rem;
	position: relative;
	gap: .4rem;
	min-height: 3rem;
	justify-self: start;
	margin-right: 2rem;
}

.Bubble div {
	word-wrap: break-word;
    hyphens: auto;
}

.Bubble__time {
	display: flex;
	justify-self: flex-end;
	font-size: 1rem;
	align-self: flex-end;
	color: var(--color-grey-dark-1)
}

.Bubble--client {
	background-color: var(--color-primary);
	color: var(--color-white);
	margin-left: 2rem;
	margin-right: 1rem;
}

.Bubble--client .Bubble__time {
	color: var(--color-white);
}

.Bubble--video {
	padding: 0;
}

.Bubble--media {
	display: grid;
	grid-auto-flow: row;
}
