.InitialTemplate__minimize {
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;
	z-index: 1;
}

.InitialTemplate {
	display: grid;
	min-height: 100vh;
	grid-template-rows: 1fr min-content;
	position: relative;
}

.InitialTemplate__background {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
}
.InitialTemplate__background > svg {
	width: 100%;
	height: auto;
}

.InitialTemplate__background > svg > use {
	fill: var(--color-primary);
}

.InitialTemplate__content {
	display: grid;
	grid-auto-rows: min-content;
	padding: 3.5rem;
	gap: 3rem;
	overflow: auto;
}

.InitialTemplate__content >*:last-child {
	margin-bottom: 2rem;
}

.InitialTemplate__omnizeInfo {
	background-image: linear-gradient(to bottom, #ffffff, #efeff4);
	height: 3.9rem;
}

.InitialTemplate__welcome {
	display: grid;
	gap: 1.5rem;
}