.AvatarQuantity {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.8rem;
	color: var(--color-grey-light-3);
	font-weight: 600;
	box-sizing: content-box;
	border-radius: 50%;
	overflow: hidden;
	width: 5rem;
	height: 5rem;
	background-color: var(--color-primary);
	position: relative;
}

.AvatarQuantity > * {
	transform: translate(-.2rem);
}

.AvatarQuantity__plus {
	font-size: 1.8rem;
}