*{
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px !important;
  background-color: var(--color-white);
  color: var(--color-grey-dark-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Italic.4f8cb602.woff2) format('woff');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Regular.d4f4acf3.woff2) format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Medium.d960b65f.woff2) format('woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-SemiBold.934e63a6.woff2) format('woff');
  font-style: normal;
  font-weight: 600;
}

input, select { font-size: 100% !important }
.Container {
  display: grid;
  height: 100vh;
  border-radius: 5px;
  overflow: hidden;
}
.Loader {
	display: -webkit-flex;
	display: flex;
	justify-self: center;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.Loader__img {
	width: 3rem;
	height: 3rem;
}

.Loader__img--small {
	width: 2.2rem;
	height: 2.2rem;
}
.InitialTemplate__minimize {
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;
	z-index: 1;
}

.InitialTemplate {
	display: grid;
	min-height: 100vh;
	grid-template-rows: 1fr -webkit-min-content;
	grid-template-rows: 1fr min-content;
	position: relative;
}

.InitialTemplate__background {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
}
.InitialTemplate__background > svg {
	width: 100%;
	height: auto;
}

.InitialTemplate__background > svg > use {
	fill: var(--color-primary);
}

.InitialTemplate__content {
	display: grid;
	grid-auto-rows: -webkit-min-content;
	grid-auto-rows: min-content;
	padding: 3.5rem;
	grid-gap: 3rem;
	gap: 3rem;
	overflow: auto;
}

.InitialTemplate__content >*:last-child {
	margin-bottom: 2rem;
}

.InitialTemplate__omnizeInfo {
	background-image: linear-gradient(to bottom, #ffffff, #efeff4);
	height: 3.9rem;
}

.InitialTemplate__welcome {
	display: grid;
	grid-gap: 1.5rem;
	gap: 1.5rem;
}
.ZenviaInfo {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 1.2rem;
}

.ZenviaInfo > img {
    height: 1.5rem;
}

.ZenviaInfo > svg path {
    fill: var(--color-grey-dark-3);
}

.OmnizeInfo > img {
	height: 2rem;
}
.heading {
    font-size: 2rem;
    font-weight: 500;
}
.WelcomeMessage {
    display: grid;
    color: var(--color-white);
    border-radius: 5px;
    grid-gap: .4rem;
    gap: .4rem;
}

.WelcomeMessage > p {
    font-size: 1.6rem;
    opacity: 0.5;
}
.ButtonIcon {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border-radius: 5px;
	border: none;
	outline: none;
	width: 5rem;
	height: 5rem;
}

.ButtonIcon:hover:not(.ButtonIcon--disabled) {
	cursor: pointer;
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
	transition: all .2s;
}

.ButtonIcon--icon-only {
	background-color: transparent;
	padding: 0;
	width: -webkit-min-content;
	width: -moz-min-content;
	width: min-content;
	height: -webkit-min-content;
	height: -moz-min-content;
	height: min-content;
}

.ButtonIcon--circle {
	border-radius: 50%;
}

.Minimize {
	font-size: 3rem;
	color: var(--color-white) !important;
	padding: 0;
}
.StartInteraction {
	display: grid;
	grid-gap: 2rem;
	gap: 2rem;
}

.StartInteraction .ResponseTime {
	font-size: 1.1rem;
	color: var(--color-grey-dark-1);
	font-weight: 500;
}

.StartInteraction a {
	text-decoration: none;
}

.StartInteraction__info {
	display: grid;
	grid-gap: .5rem;
	gap: .5rem;
}

.StartInteraction__Header {
	font-size: 1.6rem;
	font-weight: 600;
	color: var(--color-grey-dark-2);
	display: grid;
	grid-auto-flow: row;
	grid-gap: 1.5rem;
	gap: 1.5rem;
}

.StartInteraction__field {
	display: grid;
	grid-template-columns: 1fr -webkit-min-content;
	grid-template-columns: 1fr min-content;
	background-color: var(--color-white);
	border-radius: 5px;
	border: 1px solid var(--color-grey-light-2);
}

.StartInteraction__field > .Send {
	-webkit-align-self: start;
	        align-self: start;
	width: 5rem;
	height: 5rem;
}

.StartInteraction__field > .ChatInputText > .ChatInputText__input {
	font-size: 1.3rem;
	padding: 1.5rem 2rem;
	font-weight: 400;
}

.StartInteraction .AvatarName{
	border: .2rem solid var(--color-white);
}

.StartInteraction__icons {
	display: grid;
	grid-auto-flow: column;
	grid-gap: 2rem;
	gap: 2rem;
	grid-auto-columns: -webkit-min-content;
	grid-auto-columns: min-content;
}

.StartInteraction__icons__item {
	display: grid;
	grid-template-rows: 4rem -webkit-min-content;
	grid-template-rows: 4rem min-content;
	grid-gap: 1rem;
	gap: 1rem;
	width: 4rem;
	height: 4rem;
	text-align: center;
	color: var(--color-grey-dark-1);
}

.StartInteraction__icons__item > svg {
	height: 100%;
	width: 100%;
}
.StartInteraction__icons__item:last-of-type div {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	border-radius: 50%;
	background-color: var(--color-primary);
	width: 4rem;
	height: 4rem;
}

.StartInteraction__icons__item:last-of-type div > svg {
	height: 50%;
	width: 50%;
}

.StartInteraction__icons__item:last-of-type div > svg path {
	fill: var(--color-white);
}

.StartInteraction__channels {
	display: grid;
	grid-gap: 1rem;
	gap: 1rem;
}

.StartInteraction__channels > p {
	font-size: 1.4rem;
	font-weight: 600;
	color: var(--color-grey-dark-2);
}
.AvatarName {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	text-transform: uppercase;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	color: var(--color-white);
	letter-spacing: -.1rem;
	background-color: var(--color-grey-dark-2);
	position: relative;
	font-size: 1.8rem;
}

.AvatarName svg path {
	fill: var(--color-primary);
}

.AvatarName--selected {
	background-color: var(--color-white);
}

.AvatarName--selected svg path {
	fill: var(--color-grey-dark-2);
}

.AvatarName__info {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	overflow: hidden;
}

.AvatarName__info > img {
	width: 100%;
	height: 100%;
}

.AvatarName__info > svg {
	width: 40%;
	height: 40%;
}

.AvatarName--status::before {
	content: '';
	position: absolute;
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	bottom: 0;
	right: 0;
	z-index: 3;
	border: .15rem solid var(--color-grey-dark-1);
}

.AvatarName--online::before {
	background-color: var(--color-green);
}

.AvatarName--offline::before {
	background-color: var(--color-grey-dark-1);
}

.AvatarName--connecting::before {
	background-color: var(--color-orange);
}
.AvatarQuantity {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	font-size: 1.8rem;
	color: var(--color-grey-light-3);
	font-weight: 600;
	box-sizing: content-box;
	border-radius: 50%;
	overflow: hidden;
	width: 5rem;
	height: 5rem;
	background-color: var(--color-primary);
	position: relative;
}

.AvatarQuantity > * {
	-webkit-transform: translate(-.2rem);
	        transform: translate(-.2rem);
}

.AvatarQuantity__plus {
	font-size: 1.8rem;
}
.AvatarNameList {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.AvatarNameList > div {
	width: 4rem;
	height: 4rem;
	border: 3px solid var(--color-primary);
}

.AvatarNameList > div:not(:first-child) {
	-webkit-transform: translateX(-1.3rem);
	        transform: translateX(-1.3rem);
}

.AvatarNameList--status::before {
	content: '';
	position: absolute;
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	bottom: 0;
	right: 0;
	z-index: 3;
	border: .15rem solid var(--color-grey-dark-1);
	-webkit-transform: translateX(1.9rem);
	        transform: translateX(1.9rem);
}

.AvatarNameList--online::before {
	background-color: var(--color-green);
}

.AvatarNameList--offline::before {
	background-color: var(--color-grey-dark-1);
}

.AvatarNameList--connecting::before {
	background-color: var(--color-orange);
}

.AvatarNameList--small > div {
	width: 2.5rem;
	height: 2.5rem;
	margin: 0 -1.3rem 0 0;
	border: 2px solid var(--color-white);
}
.AvatarNameList--small {
	-webkit-transform: translateX(-1.2rem);
	        transform: translateX(-1.2rem);
}
.ChatInputText {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	height: 100%;
	overflow-x: hidden;
}

.ChatInputText__input {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	padding-left: 1.5rem;
	width: 100%;
	max-height: 12rem;
	overflow-y: auto;
	overflow-x: hidden;
	outline: none;
	padding: 2.2rem;
	overflow-wrap: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	    -ms-hyphens: auto;
	        hyphens: auto;
	word-break: break-word;
}

.ChatInputText__input:hover{
	cursor: text;
}

.ChatInputText__input:empty::before {
	content: attr(placeholder);
	color: var(--color-grey-light-4);
	display: block;
}

.ChatInputText--disabled {
	cursor: default;
}

.ChatInputText--disabled .ChatInputText__input {
	cursor: default;
}

.ChatInputText--disabled .ChatInputText__input:before {
	cursor: default;
}

.Send {
	display: -webkit-flex;
	display: flex;
	height: 4rem;
	width: 4rem;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.Send:hover {
	cursor: pointer;
}

.Send svg path {
	fill: var(--color-primary);
}

.Send svg {
	-webkit-transform: translateX(.2rem);
	        transform: translateX(.2rem);
}
.Box {
	padding: 2rem;
	color: var(--color-grey-dark-3);
	background-color: var(--color-white);
	border-radius: 5px;
	box-shadow: var(--shadow-light);
}

.SlideIn {
	-webkit-animation: fadeIn .3s linear;
	        animation: fadeIn .3s linear;
}

@-webkit-keyframes fadeIn {
	0% {
		-webkit-transform: translateY(300px);
		        transform: translateY(300px);
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes fadeIn {
	0% {
		-webkit-transform: translateY(300px);
		        transform: translateY(300px);
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.CustomerInfo {
	display: grid;
	grid-auto-flow: row;
	grid-gap: 1rem;
	gap: 1rem;
}

.CustomerInfo__button {
	width: 100%;
}

.CustomerInfo__button .Loader__img {
	width: 1.6rem;
	height: 1.6rem;
}

.Button {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 1.4rem;
	font-size: 1.3rem;
	border-radius: 3px;
	border: none;
	outline: none;
	font-family: 'Montserrat';
	font-weight: 500;
	color: var(--color-white);
	cursor: pointer;
}

.Button:disabled {
	cursor: default;
	background-color: var(--color-grey-light-3) !important;
	color: var(--color-grey-dark-3);
}


.Button .Loader .Loader__img {
	width: 16px;
	height: 16px;
}

.ChatTemplate {
	display: grid;
	grid-template-rows: -webkit-min-content 1fr -webkit-min-content;
	grid-template-rows: min-content 1fr min-content;
	position: relative;
	width: 100vw;
	height: 100vh;
	background-color: #ffffff;
}

.ChatTemplate__call {
	position: relative;
}

.ChatTemplate__chat {
	z-index: 1;
}

.ChatHeader {
	display: grid;
	grid-template-columns: 1fr -webkit-min-content -webkit-min-content;
	grid-template-columns: 1fr min-content min-content;
	grid-auto-flow: column;
	background-color: var(--color-primary);
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-white);
	grid-gap: .5rem;
	gap: .5rem;
	padding: 1rem 2rem;
	z-index: 10;
	height: 50px;
}

.ChatHeader .ButtonIcon {
	width: 3rem;
}

.ChatHeader__avatar {
	max-height: 45px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.ChatHeader__avatar .ResponseTime {
	font-size: 1.1rem;
}

.ChatHeader__avatar .AvatarName {
	width: 4rem;
	height: 4rem;
	background-color: var(--color-white);
}

.ChatHeader__agent-name {
	padding-left: 1.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 145px;
}

.ChatHeader__backIcon {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.ChatHeader__backIcon .ButtonIcon {
	padding-left: 10px;
}

.ChatHeader__backIcon > svg > path {
	fill: var(--color-white);
}

.ChatHeader__minimize .ButtonIcon {
	padding-left: 5px;
}

.ChatHeader__info {
	display: grid;
	grid-gap: .2rem;
	gap: .2rem;
}

.ChatHeader__agentName {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ChatHeader__lastSeen{
	font-size: 1.1rem;
	opacity: 0.5;
}

.ChatHeader__video {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.ChatHeader__video svg path {
	fill: var(--color-white);
}
.FinishModal {
	max-width: 300px;
	text-align: center;
	color: var(--color-grey-dark-1);
}

.FinishModal__actions {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	-webkit-align-items: end;
	        align-items: end;
	grid-gap: .9rem;
	gap: .9rem;
	padding-top: 2rem;
}
.Backdrop {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, .25);
	z-index: 10;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	top: 0;
	left: 0;
	border-radius: 5px;
}
.Card {
	display: grid;
	justify-items: center;
	grid-gap: 1.6rem;
	gap: 1.6rem;
	background-color: var(--color-white);
	padding: 3.5rem 2rem 3rem 2rem;
	border-radius: 8px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.Card__title {
	color: var(--color-grey-dark-1);
}

.RateNps {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 300px;
  max-width: 300px;
}

.RateNps__item {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: calc(20% - 40px);
          flex-basis: calc(20% - 40px);
}

.RateNps__label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 1.4rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: var(--color-white);
  cursor: pointer;
  color: var(--color-grey-light-3);
}

.RateNps__label:hover {
  color: var(--color-primary);
  background-color: var(--color-grey-light-3);
}

.RateNps__input {
  display: none;
}

.RateNps__input:checked + label {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.RateMood {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-justify-content: center;
          justify-content: center;
}

.RateMood__label {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 8rem;
  height: 8rem;
  cursor: pointer;
  color: var(--color-grey-light-3);
  border-radius: 5px;
}

.RateMood__label--good:hover > svg > path {
  fill: var(--color-green);
}

.RateMood__label--bad:hover > svg > path {
  fill: var(--color-red);
}

.RateMood__label:hover {
  color: var(--color-primary);
  background-color: var(--color-grey-light-1);
}

.RateMood__label > svg {
  width: 3rem;
  height: 3rem;
  margin-bottom: 5px;
}

.RateMood__label > svg > path {
  fill: var(--color-grey-light-3);
}

.RateMood__input {
  display: none;
}

.RateMood__input:checked + label {
  color: var(--color-white);
  background-color: var(--color-primary);
}

.RateMood__input:checked + label > svg > path {
  fill: var(--color-white);
}
.RateStar__form {
  display: grid;
  grid-auto-flow: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}

.RateStar input {
  display: none;
}

.RateStar__label {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 4rem;
  height: 4rem;
}

.RateStar__label:hover {
  cursor: pointer;
}

.RateStar__label > svg > path {
  fill: var(--color-grey-light-3);
}

.RateStar__label--hovered > svg > path {
  fill: var(--color-yellow);
}

.RateStar--checked label > svg > path {
  fill: var(--color-yellow);
}

.RateStar input:checked ~ label > svg > path {
  fill: var(--color-grey-light-3);
}

.RateStar input:checked + label > svg > path {
  fill: var(--color-yellow);
}
.RateModal {
	display: grid;
	justify-items: center;
	grid-gap: 1.6rem;
	gap: 1.6rem;
	background-color: var(--color-white);
	padding: 3.5rem 2rem 3rem 2rem;
	border-radius: 8px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	color: var(--color-grey-dark-1);
}

.RateModal__avatar {
	width: 7rem;
	height: 7rem;
}

.RateModal__avatar svg path {
	fill: var(--color-white);
}

.RateModal__info {
	text-align: center;
}

.RateModal p:last-child {
	cursor: pointer;
}
.Input {
	display: grid;
	-webkit-align-items: center;
	        align-items: center;
	background-color: var(--color-white);
	position: relative;
}

.Input__input {
	display: grid;
	color: var(--color-grey-dark-3);
	font-size: 1.3rem;
	font-family: 'Montserrat';
	font-weight: 500;
	border-radius: 5px;
	border: none;
	outline: none;
	padding: 1.65rem;
	padding-right: 3rem;
	background-color: var(--color-white) !important;
}

.Input::-webkit-input-placeholder {
	color: #c7c7cc;
}

.Input::-ms-input-placeholder {
	color: #c7c7cc;
}

.Input::placeholder {
	color: #c7c7cc;
}

.Input--invalid {
	color: var(--color-red) !important;
}

.Input__status-icon {
	padding: 4px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	right: 1rem;
	position: absolute;
}

.Input__status-icon path {
	fill: white;
}

.Input__invalid-icon {
	background-color: var(--color-red);
}

.Input__valid-icon {
	background-color: var(--color-green);
}

.SendChatCopyModal {
	display: grid;
	width: 80%;
	justify-items: center;
	grid-gap: 1.6rem;
	gap: 1.6rem;
	background-color: var(--color-white);
	padding: 3.5rem 2rem 3rem 2rem;
	border-radius: 8px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	color: var(--color-grey-dark-1);
}

.SendChatCopyModal__input {
	border: 1px solid var(--color-grey-light-3);
	border-radius: 3px;
	width: 100%;
}

.SendChatCopyModal__save {
	width: 100%;
}

.SingleChoice {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	padding: 0 20px 20px 20px;
	list-style: none;
	color: var(--color-primary);
}

.SingleChoice__choice {
	margin: 5px;
}

.SingleChoice__choice__label {
	display: -webkit-flex;
	display: flex;
	border: solid 2px var(--color-primary);
	padding: 1.5rem;
	border-radius: 4px;
	-webkit-justify-content: center;
	        justify-content: center;
}

.SingleChoice__choice__input {
	display: none;
}

.SingleChoice__choice__input:hover + label {
	color: var(--color-primary);
	cursor: pointer;
}

.SingleChoice__choice__input:checked + label {
	color: var(--color-white);
	background-color: var(--color-primary);
}
.OutOfServicePeriod {
	font-weight: normal;
}

.OutOfServicePeriod__title {
	font-size: 1.5rem;
	text-align: left;
	margin-bottom: .5rem;
}

.OutOfServicePeriod__period {
	font-size: 1.3rem;
}

.OutOfServicePeriod__period__weekdays {
	font-weight: bold;
}

.InfiniteList {
	height: 100%;
}

.InfiniteList--scroll-vertical {
	overflow-y: auto;
	overflow-x: hidden;
}

.InfiniteList--scroll-horizontal {
	overflow-x: scroll;
	overflow-y: hidden;
}

.InfiniteList--scroll-both {
	overflow: scroll;
}
.SelectDepartment {
	display: grid;
	grid-template-rows: -webkit-min-content 1fr -webkit-min-content;
	grid-template-rows: min-content 1fr min-content;
	position: relative;
	width: 100vw;
	height: 100vh;
	color: var(--color-primary);
}

.SelectDepartmentList {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 80vh;
}

@media screen and (max-height: 600px) {
	.SelectDepartmentList {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 75vh;
	}	
}

.SelectDepartment__choice {
	margin: 3px;
	-webkit-align-items: center;
	        align-items: center;
	border: solid 2px var(--color-primary);
}

.SelectDepartment__choice__label {
	display: -webkit-flex;
	display: flex;
	padding: 1rem;
	border-radius: 4px;
	-webkit-justify-content: left;
	        justify-content: left;
}

.SelectDepartment__choice__input {
	display: none;
}

.SelectDepartment__choice__active {
	background-color: #b7b7b7;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin: 4px 10px;
}

.SelectDepartment__choice__active__on {
	background-color: #7ed321;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin: 4px 10px;
}

.SelectDepartment__choice__message {
	margin-left: 10px;
	font-size: 12px;
	color: #b7b7b7;
	margin-top: 2px;
}

.SelectDepartment__choice__button {
	display: inline-block;
	width: 30px; height: 19px;
	position: fixed;
	right: 30px;
	background-color: transparent;
	border-top: 0px;
	border-left: 0px;
	padding: 0;
}

.SelectDepartment__choice__more {
	display: inline-block;
	border-right: 4px solid #b7b7b7;
	border-bottom: 4px solid #b7b7b7;
	width: 10px; height: 10px;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	position: relative;
	top: -2px;
	transition: all 0.2s ease;
}

.SelectDepartment__choice__less {
	display: inline-block;
	border-right: 4px solid #b7b7b7;
	border-bottom: 4px solid #b7b7b7;
	width: 10px; height: 10px;
	-webkit-transform: rotate(-135deg);
	        transform: rotate(-135deg);
	position: relative;
	top: 5px;
	transition: all 0.2s ease;
}

.SelectDepartment__choice__officeHour {
	display: block;
	color: black;
	padding: 0px 20px 10px 20px;
}

.SelectDepartment__choice__officeHour__hidden {
	display: none;
	color: black;
	padding: 0px 20px 10px 20px;
}

.SelectDepartment__choice__input:hover + label {
	color: var(--color-primary);
	cursor: pointer;
}

.SelectDepartment__choice__input:checked + label {
	color: var(--color-white);
	background-color: var(--color-primary);
}
.CustomerForm {
	padding: 0 20px 20px 20px;
	color: var(--color-grey-dark-3);
}

.CustomerForm__fields {
	display: grid;
	grid-gap: .5rem;
	gap: .5rem;
	list-style: none;
}

.CustomerForm__fields__field {
	border: 1px solid var(--color-grey-light-3);
	border-radius: 3px;
}

.CustomerForm__fields__field > input {
	width: 100%;
	box-sizing: border-box;
}

.CustomerForm__field--alert > input {
	color: var(--color-red);
}

.CustomerForm__field input {
	font-size: 1.2rem;
}

.CustomerForm__info {
	font-size: 1.2rem;
}
.Attachment__label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}

.Attachment__label--disabled {
	display: none;
}

.Attachment__label:hover:not(.Attachment__label--disabled) {
  cursor: pointer;
}

.Attachment__label:hover:not(.Attachment__label--disabled) > svg > path {
  fill: var(--color-grey-dark-1)
}

.Attachment__label > svg > path {
  fill: var(--color-grey-light-3);
}

.Attachment__label > svg {
  width: 1.97rem;
  height: 3rem;
}

.Attachment__input {
  opacity: 0;
  position: fixed;
  top: -10rem;
}
.MessageHeader {
	color: var(--color-grey-dark-3);
	padding: 20px;
}
.ChatInput {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr -webkit-min-content;
	grid-template-columns: 1fr min-content;
	-webkit-align-items: start;
	        align-items: start;
	grid-gap: 2rem;
	gap: 2rem;
	background-color: var(--color-white);
	z-index: 2;
	padding-right: 1rem;
	border-top: 1px solid var(--color-grey-light-3);
}

.ChatInput--blocked-size {
	height: 60px;
}

.ChatInput__actions {
	display: grid;
	grid-auto-flow: column;
	-webkit-align-items: center;
	        align-items: center;
	height: 50px;
}

.ChatInput__emoji {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	border-radius: 50%;
}

.ChatInput__emoji:hover:not(.ChatInput__emoji--disabled) {
	background-color: var(--color-grey-light-3);
	cursor: pointer;
}

.ChatInput__emoji:hover:not(.ChatInput__emoji--disabled) > svg > path {
	fill: var(--color-grey-dark-2);
}

.ChatInput__emoji > svg > path {
	fill: var(--color-grey-light-3);
}

.ChatInput__emojiPicker .emoji-mart {
	width: 100vw !important;
}

.ChatInput--ended {
	background-color: var(--color-primary);
}

.ChatInput--ended .ChatInput__actions {
	display: none;
}

.ChatInput--ended .ChatInputText__input {
	display: none;
}

.ChatInput__message {
	padding: 2rem;
	text-align: center;
	color: var(--color-white);
	text-transform: uppercase;
	font-weight: bold;
}
.MessageList {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 0 4rem 0;
}

.MessageList .InfiniteList > * {
	margin-bottom: 1rem;
}

.MessageList--call {
	height: 17.1rem;
}

.MessageList > .OmnizeInfo {
	margin-top: -1rem;
}

.MessageList__logo {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
}

.MessageList__item {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	grid-gap: .9rem;
	gap: .9rem;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	width: 100%;
}

.MessageList__item:first-child {
	margin-top: 3rem;
}

.MessageList__item:last-child {
	margin-bottom: 3rem;
}

.MessageList__item .AvatarName {
	width: 3.5rem;
	height: 3.5rem;
	background-color: var(--color-primary);
	margin: 0 1rem;
}

.MessageList__item .AvatarInfo {
	width: 3.5rem;
	height: 3.5rem;
}

.MessageList__item .AvatarName path {
	fill: var(--color-white);
}

.MessageList__item--client {
	-webkit-justify-content: end;
	        justify-content: end;
}
.Bubble {
	display: grid;
	grid-auto-flow: column;
	-webkit-align-items: center;
	        align-items: center;
	border-radius: 4px;
	background-color: var(--color-grey-light-1);
	padding: 1rem;
	position: relative;
	grid-gap: .4rem;
	gap: .4rem;
	min-height: 3rem;
	justify-self: start;
	margin-right: 2rem;
}

.Bubble div {
	word-wrap: break-word;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

.Bubble__time {
	display: -webkit-flex;
	display: flex;
	justify-self: flex-end;
	font-size: 1rem;
	-webkit-align-self: flex-end;
	        align-self: flex-end;
	color: var(--color-grey-dark-1)
}

.Bubble--client {
	background-color: var(--color-primary);
	color: var(--color-white);
	margin-left: 2rem;
	margin-right: 1rem;
}

.Bubble--client .Bubble__time {
	color: var(--color-white);
}

.Bubble--video {
	padding: 0;
}

.Bubble--media {
	display: grid;
	grid-auto-flow: row;
}

.MessageAudio {
	display: grid;
  	grid-auto-flow: column;
  	-webkit-justify-content: start;
  	        justify-content: start;
  	-webkit-align-items: end;
  	        align-items: end;
  	grid-gap: .5rem;
  	gap: .5rem;
  	justify-content: start;
  	width: 100%;
}

.MessageAudio--client {
	-webkit-justify-content: end;
	        justify-content: end;
}

.MessageAudio audio {
	max-width: 15rem;
}
.MessageVideo {
	display: grid;
  	grid-auto-flow: column;
  	-webkit-justify-content: start;
  	        justify-content: start;
  	-webkit-align-items: end;
  	        align-items: end;
  	grid-gap: .5rem;
  	gap: .5rem;
  	justify-content: start;
  	width: 100%;
}

.MessageVideo video {
	max-width: 20rem;
	border-radius: .4rem;
}

.MessageVideo--client {
	-webkit-justify-content: end;
	        justify-content: end;
}
.File {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.3rem;
  color: var(--color-grey-dark-1);
}

.File__info p:first-child {
  color: var(--color-primary);
}

.File__info--client {
  color: rgba(255, 255, 255, 0.5);
}

.File__info--client p:first-child {
  color: var(--color-white);
}

.File .File__info--client svg path {
  fill: var(--color-white);
}

.File svg path {
	fill: var(--color-primary);
}

.File svg {
	height: 3rem;
	width: 3rem;
}
.MessageFile {
	display: grid;
  	grid-auto-flow: column;
  	-webkit-justify-content: start;
  	        justify-content: start;
  	-webkit-align-items: end;
  	        align-items: end;
  	grid-gap: .5rem;
  	gap: .5rem;
  	justify-content: start;
  	width: 100%;
}

.MessageFile--client {
	-webkit-justify-content: end;
	        justify-content: end;
}

.MessageFile__link {
	text-decoration: none;
}

.MessageText {
  display: grid;
  grid-auto-flow: column;
  -webkit-justify-content: start;
          justify-content: start;
  -webkit-align-items: end;
          align-items: end;
  grid-gap: .5rem;
  gap: .5rem;
  justify-content: start;
  width: 100%;
}

.MessageText .AvatarName {
	width: 3.2rem;
	height: 3.2rem;
	background-color: var(--color-primary);
}

.MessageText .AvatarName path {
	fill: var(--color-white);
}

.MessageText--client {
  -webkit-justify-content: end;
          justify-content: end;
}
.MessageImage {
	display: grid;
  	grid-auto-flow: column;
  	-webkit-justify-content: start;
  	        justify-content: start;
  	-webkit-align-items: end;
  	        align-items: end;
  	grid-gap: .5rem;
  	gap: .5rem;
  	justify-content: start;
  	width: 100%;
}

.MessageImage img {
	max-width: 20rem;
}

.MessageImage--client {
	-webkit-justify-content: end;
	        justify-content: end;
}
.MessageTyping {
	display: grid;
	grid-auto-flow: column;
	-webkit-justify-content: end;
	        justify-content: end;
	-webkit-align-items: end;
	        align-items: end;
	grid-gap: .5rem;
	gap: .5rem;
}

.MessageTyping--client {
	-webkit-justify-content: start;
	        justify-content: start;
}

.MessageTyping__animation {
	width: 2.4rem;
	padding: 0 1rem;
}

.MessageTyping__animation .dot {
	display:inline-block;
	width:.5rem;
	height:.5rem;
	border-radius:50%;
	margin-right:3px;
	background: var(--color-grey-dark-2);
	-webkit-animation: wave 1.3s linear infinite;
	        animation: wave 1.3s linear infinite;
}

.MessageTyping__animation .dot:nth-child(2) {
	-webkit-animation-delay: -1.1s;
	        animation-delay: -1.1s;
}

.MessageTyping__animation .dot:nth-child(3) {
	-webkit-animation-delay: -0.9s;
	        animation-delay: -0.9s;
}

.MessageTyping > .Bubble {
	padding: 1rem;
	height: 4rem;
	margin-right: -1rem;
	position: relative;
}

@-webkit-keyframes wave {
	0%, 60%, 100% {
		-webkit-transform: none;
		        transform: none;
		-webkit-transform: initial;
		        transform: initial;
	}

	30% {
		-webkit-transform: translateY(-.6rem);
		        transform: translateY(-.6rem);
	}
}

@keyframes wave {
	0%, 60%, 100% {
		-webkit-transform: none;
		        transform: none;
		-webkit-transform: initial;
		        transform: initial;
	}

	30% {
		-webkit-transform: translateY(-.6rem);
		        transform: translateY(-.6rem);
	}
}
.CallControls {
	display: grid;
	grid-gap: 2rem;
	gap: 2rem;
	justify-items: center;
	color: var(--color-white);
}

.CallControls__button > button > svg > path {
	fill: var(--color-white);
}

.CallControls__button--disabled > button > svg > path {
	fill: var(--color-grey-dark-2)
}

.CallControls__button {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 4.5rem;
	height: 4.5rem;
	border-radius: 50%;
}

.CallControls__buttons {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: repeat(4, -webkit-max-content);
	grid-template-columns: repeat(4, max-content);
	grid-gap: .5rem;
	gap: .5rem;
	justify-items: center;
	-webkit-align-items: center;
	        align-items: center;
}

.CallControls__button > button {
	width: 4.5rem;
	height: 4.5rem;
}
.CallControls__button svg {
	width: 2rem;
	height: 2rem;
}

.Call{
  width: 100vw;
}

.Call__cam {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  background-color: #c7c7cc;
}

.Call__cam__video {
  width: 100%;
  height: 27.5rem;
}

.Call__cam__video--expanded {
  width: 100%;
  height: 55.3rem;
}

.Call__cam--mini {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background-color: #8e8e93;
  border-radius: 3px;
}

.Call__cam--mini > video {
  width: 10.5rem;
  height: 8rem;
}

.Call__controls {
  position: absolute;
  bottom: 2rem;
}
.WaitMedia, .WaitMedia .ImageContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;    
    text-align: center;
}

.WaitMedia {
    z-index: 1;
}

.WaitMedia .OpacityBG {
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #FFF;
}

.WaitMedia .ImageContainer {
    padding: 10px;
}

.WaitMedia .ImageContainer img {
    max-width: 100%;
    max-height: 100%;
}
.ErrorTemplate {
	padding: 10px;
	max-width: 300px;
	max-height: 200px;
}

.ErrorTemplate .Input {
	margin-top: 20px;
	margin-bottom: 5px;
}


.TransferTemplate {
	position: fixed;
	width: 100%;
	height: 100%;
	display: grid;
	-webkit-align-content: center;
	        align-content: center;
	justify-items: center;
	background-color: var(--color-white);
	grid-gap: 4rem;
	gap: 4rem;
	font-size: 1.6rem;
	font-weight: 500;
	text-transform: uppercase;
}

.TransferTemplate__icon {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 10rem;
	height: 10rem;
	border-radius: 50%;
	background-color: var(--color-grey-light-3);
	-webkit-animation: expanding 2s infinite;
	        animation: expanding 2s infinite;
}

.TransferTemplate__icon svg {
	height: 7rem;
	width: 7rem;
}

.TransferTemplate__icon path {
	fill: var(--color-white);
}

@-webkit-keyframes expanding {
	50% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1)
	}
}

@keyframes expanding {
	50% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1)
	}
}
