.InfiniteList {
	height: 100%;
}

.InfiniteList--scroll-vertical {
	overflow-y: auto;
	overflow-x: hidden;
}

.InfiniteList--scroll-horizontal {
	overflow-x: scroll;
	overflow-y: hidden;
}

.InfiniteList--scroll-both {
	overflow: scroll;
}