.Input {
	display: grid;
	align-items: center;
	background-color: var(--color-white);
	position: relative;
}

.Input__input {
	display: grid;
	color: var(--color-grey-dark-3);
	font-size: 1.3rem;
	font-family: 'Montserrat';
	font-weight: 500;
	border-radius: 5px;
	border: none;
	outline: none;
	padding: 1.65rem;
	padding-right: 3rem;
	background-color: var(--color-white) !important;
}

.Input::placeholder {
	color: #c7c7cc;
}

.Input--invalid {
	color: var(--color-red) !important;
}

.Input__status-icon {
	padding: 4px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	right: 1rem;
	position: absolute;
}

.Input__status-icon path {
	fill: white;
}

.Input__invalid-icon {
	background-color: var(--color-red);
}

.Input__valid-icon {
	background-color: var(--color-green);
}
