.RateMood {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: center;
}

.RateMood__label {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  cursor: pointer;
  color: var(--color-grey-light-3);
  border-radius: 5px;
}

.RateMood__label--good:hover > svg > path {
  fill: var(--color-green);
}

.RateMood__label--bad:hover > svg > path {
  fill: var(--color-red);
}

.RateMood__label:hover {
  color: var(--color-primary);
  background-color: var(--color-grey-light-1);
}

.RateMood__label > svg {
  width: 3rem;
  height: 3rem;
  margin-bottom: 5px;
}

.RateMood__label > svg > path {
  fill: var(--color-grey-light-3);
}

.RateMood__input {
  display: none;
}

.RateMood__input:checked + label {
  color: var(--color-white);
  background-color: var(--color-primary);
}

.RateMood__input:checked + label > svg > path {
  fill: var(--color-white);
}