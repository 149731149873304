.File {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;
  font-size: 1.3rem;
  color: var(--color-grey-dark-1);
}

.File__info p:first-child {
  color: var(--color-primary);
}

.File__info--client {
  color: rgba(255, 255, 255, 0.5);
}

.File__info--client p:first-child {
  color: var(--color-white);
}

.File .File__info--client svg path {
  fill: var(--color-white);
}

.File svg path {
	fill: var(--color-primary);
}

.File svg {
	height: 3rem;
	width: 3rem;
}