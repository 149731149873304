.StartInteraction {
	display: grid;
	gap: 2rem;
}

.StartInteraction .ResponseTime {
	font-size: 1.1rem;
	color: var(--color-grey-dark-1);
	font-weight: 500;
}

.StartInteraction a {
	text-decoration: none;
}

.StartInteraction__info {
	display: grid;
	gap: .5rem;
}

.StartInteraction__Header {
	font-size: 1.6rem;
	font-weight: 600;
	color: var(--color-grey-dark-2);
	display: grid;
	grid-auto-flow: row;
	gap: 1.5rem;
}

.StartInteraction__field {
	display: grid;
	grid-template-columns: 1fr min-content;
	background-color: var(--color-white);
	border-radius: 5px;
	border: 1px solid var(--color-grey-light-2);
}

.StartInteraction__field > .Send {
	align-self: start;
	width: 5rem;
	height: 5rem;
}

.StartInteraction__field > .ChatInputText > .ChatInputText__input {
	font-size: 1.3rem;
	padding: 1.5rem 2rem;
	font-weight: 400;
}

.StartInteraction .AvatarName{
	border: .2rem solid var(--color-white);
}

.StartInteraction__icons {
	display: grid;
	grid-auto-flow: column;
	gap: 2rem;
	grid-auto-columns: min-content;
}

.StartInteraction__icons__item {
	display: grid;
	grid-template-rows: 4rem min-content;
	gap: 1rem;
	width: 4rem;
	height: 4rem;
	text-align: center;
	color: var(--color-grey-dark-1);
}

.StartInteraction__icons__item > svg {
	height: 100%;
	width: 100%;
}
.StartInteraction__icons__item:last-of-type div {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: var(--color-primary);
	width: 4rem;
	height: 4rem;
}

.StartInteraction__icons__item:last-of-type div > svg {
	height: 50%;
	width: 50%;
}

.StartInteraction__icons__item:last-of-type div > svg path {
	fill: var(--color-white);
}

.StartInteraction__channels {
	display: grid;
	gap: 1rem;
}

.StartInteraction__channels > p {
	font-size: 1.4rem;
	font-weight: 600;
	color: var(--color-grey-dark-2);
}