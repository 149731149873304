.Send {
	display: flex;
	height: 4rem;
	width: 4rem;
	align-items: center;
	justify-content: center;
}

.Send:hover {
	cursor: pointer;
}

.Send svg path {
	fill: var(--color-primary);
}

.Send svg {
	transform: translateX(.2rem);
}