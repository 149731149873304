.OutOfServicePeriod {
	font-weight: normal;
}

.OutOfServicePeriod__title {
	font-size: 1.5rem;
	text-align: left;
	margin-bottom: .5rem;
}

.OutOfServicePeriod__period {
	font-size: 1.3rem;
}

.OutOfServicePeriod__period__weekdays {
	font-weight: bold;
}
