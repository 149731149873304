.RateNps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 300px;
  max-width: 300px;
}

.RateNps__item {
  flex: 0 0 calc(20% - 40px);
}

.RateNps__label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: var(--color-white);
  cursor: pointer;
  color: var(--color-grey-light-3);
}

.RateNps__label:hover {
  color: var(--color-primary);
  background-color: var(--color-grey-light-3);
}

.RateNps__input {
  display: none;
}

.RateNps__input:checked + label {
  background-color: var(--color-primary);
  color: var(--color-white);
}