.WaitMedia, .WaitMedia .ImageContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;    
    text-align: center;
}

.WaitMedia {
    z-index: 1;
}

.WaitMedia .OpacityBG {
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #FFF;
}

.WaitMedia .ImageContainer {
    padding: 10px;
}

.WaitMedia .ImageContainer img {
    max-width: 100%;
    max-height: 100%;
}