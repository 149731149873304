.Call{
  width: 100vw;
}

.Call__cam {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #c7c7cc;
}

.Call__cam__video {
  width: 100%;
  height: 27.5rem;
}

.Call__cam__video--expanded {
  width: 100%;
  height: 55.3rem;
}

.Call__cam--mini {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background-color: #8e8e93;
  border-radius: 3px;
}

.Call__cam--mini > video {
  width: 10.5rem;
  height: 8rem;
}

.Call__controls {
  position: absolute;
  bottom: 2rem;
}