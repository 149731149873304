.SlideIn {
	animation: fadeIn .3s linear;
}

@keyframes fadeIn {
	0% {
		transform: translateY(300px);
	}
	100% {
		transform: translateY(0);
	}
}
