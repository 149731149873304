.AvatarName {
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	color: var(--color-white);
	letter-spacing: -.1rem;
	background-color: var(--color-grey-dark-2);
	position: relative;
	font-size: 1.8rem;
}

.AvatarName svg path {
	fill: var(--color-primary);
}

.AvatarName--selected {
	background-color: var(--color-white);
}

.AvatarName--selected svg path {
	fill: var(--color-grey-dark-2);
}

.AvatarName__info {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	overflow: hidden;
}

.AvatarName__info > img {
	width: 100%;
	height: 100%;
}

.AvatarName__info > svg {
	width: 40%;
	height: 40%;
}

.AvatarName--status::before {
	content: '';
	position: absolute;
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	bottom: 0;
	right: 0;
	z-index: 3;
	border: .15rem solid var(--color-grey-dark-1);
}

.AvatarName--online::before {
	background-color: var(--color-green);
}

.AvatarName--offline::before {
	background-color: var(--color-grey-dark-1);
}

.AvatarName--connecting::before {
	background-color: var(--color-orange);
}