.CustomerForm {
	padding: 0 20px 20px 20px;
	color: var(--color-grey-dark-3);
}

.CustomerForm__fields {
	display: grid;
	gap: .5rem;
	list-style: none;
}

.CustomerForm__fields__field {
	border: 1px solid var(--color-grey-light-3);
	border-radius: 3px;
}

.CustomerForm__fields__field > input {
	width: 100%;
	box-sizing: border-box;
}

.CustomerForm__field--alert > input {
	color: var(--color-red);
}

.CustomerForm__field input {
	font-size: 1.2rem;
}

.CustomerForm__info {
	font-size: 1.2rem;
}