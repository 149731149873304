.ChatInput {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 1fr min-content;
	align-items: start;
	gap: 2rem;
	background-color: var(--color-white);
	z-index: 2;
	padding-right: 1rem;
	border-top: 1px solid var(--color-grey-light-3);
}

.ChatInput--blocked-size {
	height: 60px;
}

.ChatInput__actions {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	height: 50px;
}

.ChatInput__emoji {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.ChatInput__emoji:hover:not(.ChatInput__emoji--disabled) {
	background-color: var(--color-grey-light-3);
	cursor: pointer;
}

.ChatInput__emoji:hover:not(.ChatInput__emoji--disabled) > svg > path {
	fill: var(--color-grey-dark-2);
}

.ChatInput__emoji > svg > path {
	fill: var(--color-grey-light-3);
}

.ChatInput__emojiPicker .emoji-mart {
	width: 100vw !important;
}

.ChatInput--ended {
	background-color: var(--color-primary);
}

.ChatInput--ended .ChatInput__actions {
	display: none;
}

.ChatInput--ended .ChatInputText__input {
	display: none;
}

.ChatInput__message {
	padding: 2rem;
	text-align: center;
	color: var(--color-white);
	text-transform: uppercase;
	font-weight: bold;
}