.Loader {
	display: flex;
	justify-self: center;
	justify-content: center;
	align-items: center;
}

.Loader__img {
	width: 3rem;
	height: 3rem;
}

.Loader__img--small {
	width: 2.2rem;
	height: 2.2rem;
}