.Card {
	display: grid;
	justify-items: center;
	gap: 1.6rem;
	background-color: var(--color-white);
	padding: 3.5rem 2rem 3rem 2rem;
	border-radius: 8px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.Card__title {
	color: var(--color-grey-dark-1);
}
