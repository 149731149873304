.MessageList {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 0 4rem 0;
}

.MessageList .InfiniteList > * {
	margin-bottom: 1rem;
}

.MessageList--call {
	height: 17.1rem;
}

.MessageList > .OmnizeInfo {
	margin-top: -1rem;
}

.MessageList__logo {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
}

.MessageList__item {
	display: flex;
	align-items: flex-end;
	gap: .9rem;
	justify-content: flex-start;
	width: 100%;
}

.MessageList__item:first-child {
	margin-top: 3rem;
}

.MessageList__item:last-child {
	margin-bottom: 3rem;
}

.MessageList__item .AvatarName {
	width: 3.5rem;
	height: 3.5rem;
	background-color: var(--color-primary);
	margin: 0 1rem;
}

.MessageList__item .AvatarInfo {
	width: 3.5rem;
	height: 3.5rem;
}

.MessageList__item .AvatarName path {
	fill: var(--color-white);
}

.MessageList__item--client {
	justify-content: end;
}