.MessageAudio {
	display: grid;
  	grid-auto-flow: column;
  	justify-content: start;
  	align-items: end;
  	gap: .5rem;
  	justify-content: start;
  	width: 100%;
}

.MessageAudio--client {
	justify-content: end;
}

.MessageAudio audio {
	max-width: 15rem;
}