.MessageText {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: end;
  gap: .5rem;
  justify-content: start;
  width: 100%;
}

.MessageText .AvatarName {
	width: 3.2rem;
	height: 3.2rem;
	background-color: var(--color-primary);
}

.MessageText .AvatarName path {
	fill: var(--color-white);
}

.MessageText--client {
  justify-content: end;
}