.ButtonIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	border: none;
	outline: none;
	width: 5rem;
	height: 5rem;
}

.ButtonIcon:hover:not(.ButtonIcon--disabled) {
	cursor: pointer;
	transform: scale(1.1);
	transition: all .2s;
}

.ButtonIcon--icon-only {
	background-color: transparent;
	padding: 0;
	width: min-content;
	height: min-content;
}

.ButtonIcon--circle {
	border-radius: 50%;
}
