.WelcomeMessage {
    display: grid;
    color: var(--color-white);
    border-radius: 5px;
    gap: .4rem;
}

.WelcomeMessage > p {
    font-size: 1.6rem;
    opacity: 0.5;
}