.ChatTemplate {
	display: grid;
	grid-template-rows: min-content 1fr min-content;
	position: relative;
	width: 100vw;
	height: 100vh;
	background-color: #ffffff;
}

.ChatTemplate__call {
	position: relative;
}

.ChatTemplate__chat {
	z-index: 1;
}
