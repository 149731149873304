.MessageFile {
	display: grid;
  	grid-auto-flow: column;
  	justify-content: start;
  	align-items: end;
  	gap: .5rem;
  	justify-content: start;
  	width: 100%;
}

.MessageFile--client {
	justify-content: end;
}

.MessageFile__link {
	text-decoration: none;
}
